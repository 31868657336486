// reducer.ts
import { combineReducers } from "redux";
import { LocalVariable } from "./types"; // Import LocalVariable

// Define the action type
interface ThemeChangerAction {
  type: "ThemeChanger";
  payload: Partial<LocalVariable>; // Adjust based on what is being passed in the action
}

// Union of all action types
type Action = ThemeChangerAction;

const initialState: LocalVariable = {
  lang: "en",
  dir: "ltr",
  class: "dark", // Set default to dark
  dataMenuStyles: "dark",
  dataNavLayout: "vertical",
  dataHeaderStyles: "dark", // Set default to dark
  dataVerticalStyle: "overlay",
  dataToggled: "",
  dataNavStyle: "",
  horStyle: "",
  dataPageStyle: "regular",
  dataWidth: "fullwidth",
  dataMenuPosition: "fixed",
  dataHeaderPosition: "fixed",
  loader: "disable",
  iconOverlay: "",
  colorPrimaryRgb: "",
  colorPrimary: "",
  bodyBg: "",
  Light: "",
  darkBg: "",
  inputBorder: "",
  bgImg: "",
  iconText: "",
  body: "",
};

const localVariableReducer = (
  state: LocalVariable = initialState,
  action: Action
): LocalVariable => {
  switch (action.type) {
    case "ThemeChanger":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  local_variable: localVariableReducer,
});

export default rootReducer;
