"use client";
import "./globals.scss";
import { Provider } from "react-redux";
import store from "@/shared/redux/store";
import PrelineScript from "./PrelineScript";
import { useState, ReactNode, useMemo } from "react";
import { Initialload } from "@/shared/contextapi";

interface RootLayoutProps {
  children: ReactNode;
}

const RootLayout = ({ children }: RootLayoutProps) => {
  const [pageloading, setpageloading] = useState(false);
  const contextValue = useMemo(
    () => ({ pageloading, setpageloading }),
    // eslint-disable-next-line prettier/prettier
    [pageloading]
  );

  return (
    <html lang="en" className="dark">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Headerbiddr Dashboard</title>
      </head>
      <body className="dark">
        <Provider store={store}>
          <Initialload.Provider value={contextValue}>
            {children}
          </Initialload.Provider>
        </Provider>
        <PrelineScript />
      </body>
    </html>
  );
};

export default RootLayout;
